import { default as _91_46_46_46route_93MLFNaXzaeIMeta } from "/builds/webcontact/rheinau/pages/[...route].vue?macro=true";
import { default as indexDb91kriHFqMeta } from "/builds/webcontact/rheinau/pages/index.vue?macro=true";
export default [
  {
    name: "route",
    path: "/:route(.*)*",
    component: () => import("/builds/webcontact/rheinau/pages/[...route].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/webcontact/rheinau/pages/index.vue")
  }
]